.search {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .search-fields {
    display: flex;
    gap: 12px;
    width: 100%;

    .loc-search {
      cursor: pointer;
      flex: 1;
      box-shadow: 1px 2px 8px rgba(var(--K800-rgb), 0.1);
    }
  }

  .last-fetched {
    font-size: 13px;
    font-weight: 500;
    color: var(--K500);
  }
}
