@import "../../css/media.scss";

.current {
  display: flex;
  align-items: center;
  gap: 20px;
  width: 100%;
  padding: 20px;

  @media #{$query-min-sm} {
    justify-content: space-between;
  }
  @media #{$query-max-sm} {
    flex-wrap: wrap;
    align-items: center;

    .current__condition,
    .current__temp,
    .current__wind {
      flex: 1;
    }

    .current__condition {
      order: 1;
    }
    .current__wind {
      order: 2;
    }
    .current__temp {
      order: 3;
      width: 100%;
      justify-content: center;
    }
  }

  &__condition {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4px;

    .weather-icon {
      display: block;
      aspect-ratio: 1;
      width: 120px;
      margin-top: -14px;
    }

    .description {
      font-size: 16px;
      font-weight: 700;
      text-transform: capitalize;
      color: var(--icon-cloud);
    }
  }

  &__wind {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__temp {
    display: flex;
    align-items: center;
    gap: 30px;

    &__left {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;

      .temp-actual {
        display: flex;
        align-items: flex-start;

        &__number {
          font-size: 60px;
          font-weight: 700;
          line-height: 60px;
        }
        &__unit {
          font-size: 24px;
          font-weight: 500;
          line-height: 36px;
        }
      }

      .temp-feel {
        display: flex;
        align-items: center;
        gap: 4px;

        &__label {
          font-size: 16px;
          font-weight: 500;
          color: var(--K600);
        }
        &__temp {
          font-size: 18px;
          font-weight: 700;
        }
      }
    }
    &__right {
      ul {
        display: flex;
        flex-direction: column;

        li {
          list-style-type: none;
          display: flex;
          justify-content: space-between;
          gap: 4px;
          flex: 1;

          font-size: 14px;
          line-height: 1.5;
          color: var(--K600);

          span:first-child {
            font-weight: 600;
          }
          span:last-child {
            font-weight: 700;
          }
        }
      }
    }
  }
}
