.moon-phase {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  aspect-ratio: 1;

  border-radius: 50%;
  background: var(--moon);

  svg {
    position: absolute;
    display: block;
    width: 26px;
    height: 26px;

    path {
      fill: var(--moonlight);
    }
  }
}
