.spinner {
  &--centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  svg {
    animation: 2.5s linear infinite spinner-svg;
    overflow: visible;
  }

  circle {
    animation: 2.5s ease-in-out infinite both spinner-circle;
    display: block;
    fill: transparent;
    stroke: var(--K400);
    stroke-linecap: round;
    stroke-dasharray: 280;
    stroke-dashoffset: 280;
    transform-origin: 50% 50%;
  }

  &--sm {
    svg {
      width: 20px;
      height: 20px;
    }
    circle {
      stroke-width: 12px;
    }
  }
  &--md {
    svg {
      width: 50px;
      height: 50px;
    }
    circle {
      stroke-width: 7px;
    }
  }
  &--lg {
    svg {
      width: 100px;
      height: 100px;
    }
    circle {
      stroke-width: 6px;
    }
  }

  @keyframes spinner-svg {
    0% {
      transform: rotateZ(0deg);
    }
    100% {
      transform: rotateZ(360deg);
    }
  }

  @keyframes spinner-circle {
    0%,
    25% {
      stroke-dashoffset: 280;
      transform: rotate(0);
    }

    50%,
    75% {
      stroke-dashoffset: 40;
      transform: rotate(45deg);
    }

    100% {
      stroke-dashoffset: 280;
      transform: rotate(360deg);
    }
  }
}
