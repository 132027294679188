.location {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  width: 100%;
  height: 100%;
  min-height: 184px;
  padding: 20px;

  &__info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    flex: 1;

    &__name {
      display: flex;
      flex-direction: column;
      gap: 4px;

      line-height: 1.2;

      .city {
        font-size: 18px;
        font-weight: 700;
      }
      .state-country {
        font-size: 14px;
        font-weight: 500;
      }
    }

    &__time {
      display: flex;
      flex-direction: column;
      gap: 6px;
      text-align: right;

      .time {
        &__numbers {
          margin-right: 4px;
          font-size: 38px;
          font-weight: 700;
        }
        &__period {
          font-size: 14px;
          font-weight: 700;
        }
      }
      .date {
        font-size: 16px;
        font-weight: 700;
      }
    }
  }
}
