@import "../../css/media.scss";

.box {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;

  background: var(--K100);

  @media #{$query-min-md} {
    border-radius: var(--box-border-radius);
    box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.06),
      6px 16px 30px rgba(0, 0, 0, 0.04);
  }
  @media #{$query-max-md} {
    box-shadow: 1px 2px 4px rgba(var(--K700-rgb), 0.08),
      2px 4px 8px rgba(var(--K700-rgb), 0.08);
  }

  &:not(.box--allow-overflow) {
    overflow-x: auto;
  }

  &__loading {
    pointer-events: none;
    user-select: none;
    transition: 0.25s ease opacity;
    overflow: hidden;
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    border-radius: var(--box-border-radius);
    background: var(--K100);

    &--hide {
      opacity: 0;
      transition: 0.75s ease opacity;
    }
  }
}
