.daily {
  $shift: 100% / 8;

  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
  min-width: 800px;
  padding: 30px var(--box-side-padding);

  .blocks {
    margin-left: -$shift / 2;
    width: calc(100% + $shift - 1px);
  }
}

.data-loading .box--daily {
  min-height: 516px;
}
