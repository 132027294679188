@import "../../css/media.scss";

.alerts {
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  width: 100%;

  @media #{$query-max-sm} {
    padding: 0 12px;
  }
}

.alert {
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding: 10px 14px 10px 44px;

  overflow: hidden;

  border-radius: 4px;
  background: var(--status-red);
  box-shadow: 1px 2px 4px rgba(var(--K800-rgb), 0.1);

  color: var(--white);
  line-height: 1.4;

  &--expanded {
    .alert__description--truncated {
      display: none;
    }
  }

  &--not-expanded {
    .alert__description--full,
    .alert__sender-timing {
      display: none;
    }
  }

  svg {
    position: absolute;
    top: 20px;
    left: 10px;
    flex-shrink: 0;
    width: 24px;
    height: 24px;

    path {
      fill: var(--white);
    }
  }

  &__title {
    font-size: 18px;
    font-weight: 700;
    text-transform: capitalize;
  }

  &__description {
    font-size: 15px;
    font-weight: 400;

    &--truncated {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__sender-timing {
    margin-top: 12px;
    font-size: 14px;
    font-weight: 400;
    opacity: 0.7;
  }
}
