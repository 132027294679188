.hourly {
  $shift: 100% / 12;

  position: relative;
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
  min-width: 800px;
  padding: 84px var(--box-side-padding) 30px;

  .blocks {
    margin-left: -$shift/2;
    width: calc(100% + $shift);
  }
}

.data-loading .box--hourly {
  min-height: 506px;
}
