.wind-arrow {
  $size: 60px;

  display: flex;
  flex-direction: column;
  align-items: center;
  width: $size;
  gap: 10px;

  transform-origin: center;

  svg {
    display: block;
    width: $size / 2;
    height: $size / 2;

    path {
      fill: var(--wind);
    }
  }

  &__text {
    font-weight: 700;
    color: var(--wind);

    &__number {
      margin-right: 2px;
      font-size: 18px;
    }
    &__unit {
      font-size: 9px;
    }
  }

  &--large {
    width: 100px;
    gap: 20px;

    svg {
      width: 56px;
      height: 56px;
    }

    .wind-arrow__text {
      &__number {
        margin-right: 4px;
        font-size: 26px;
      }
      &__unit {
        font-size: 16px;
      }
    }
  }
}
