.hourly-cycles-wrap {
  overflow: hidden;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
  padding: 0 var(--box-side-padding);
}

.hourly-cycles {
  position: absolute;
  top: 0;
  left: var(--box-side-padding);
  width: calc(100% - (var(--box-side-padding) * 2));
  height: 100%;

  background: var(--K125);

  .hourly-cycle {
    position: absolute;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 28px;
    padding: 0 8px;

    border-radius: 4px;

    &--day {
      z-index: 1;
      height: 100%;

      background: var(--K100);
    }

    &--moon {
      z-index: 2;
      top: 0;

      background: rgba(var(--moonrise-rgb), 0.2);

      svg path {
        fill: var(--moonrise);
      }
    }

    &--sun {
      z-index: 2;
      top: 34px;

      background: rgba(var(--sunrise-rgb), 0.2);

      svg path {
        fill: var(--sunrise);
      }
    }

    svg {
      width: 24px;
      height: 24px;
    }

    span {
      font-size: 12px;
      font-weight: 500;
    }
  }
}
