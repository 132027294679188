.graph-container {
  position: relative;
  z-index: 3;
  width: 100%;
  height: 120px;

  &--short {
    height: 60px;
  }
}

.weather-icon {
  aspect-ratio: 1;

  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.recharts-label {
  font-size: 14px;
  font-weight: 700;
}

.recharts-surface {
  overflow: visible;
}

.graph-container--hourly-rain .recharts-label,
.graph-container--hourly-temp .recharts-label,
.blocks .block--limit-num {
  &:not(:nth-child(4n - 2)) {
    display: none;
  }
}
