@import "./media.scss";

#root {
  width: 100%;
}
.layout-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: 100%;
  max-width: 1000px;
  min-height: 100vh;

  @media #{$query-min-md} {
    padding: 40px;
  }
  @media #{$query-max-md} {
    padding: 12px 0;
  }
}

.layout {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  &__top {
    display: flex;
    justify-content: center;
    width: 100%;

    @media #{$query-max-md} {
      flex-direction: column;
    }

    &__location {
      z-index: 5;

      @media #{$query-min-md} {
        width: 350px;
      }
    }

    &__current {
      flex: 1;
    }
  }

  @media #{$query-min-md} {
    &,
    &__top {
      gap: 20px;
    }
  }
  @media #{$query-max-md} {
    &,
    &__top {
      gap: 10px;
    }
  }
}

.blocks {
  position: relative;
  z-index: 2;
  display: flex;
  width: 100%;

  .block {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    gap: 4px;

    .wind-speed {
      font-size: 14px;
      font-weight: 600;
      color: var(--wind);
    }

    .weather-icon {
      width: 46px;
    }
    .title {
      font-weight: 700;
    }

    &--night {
      background: rgba(0, 0, 0, 0.1);
    }
  }

  &--small .block {
    .title {
      font-size: 14px;
    }
  }
  &--large .block {
    .title {
      font-size: 18px;
    }
  }
}
