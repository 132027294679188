@import url("https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@300;400;500;600;700&display=swap");
@import "./media.scss";

$theme-colors: (
  "black": #000000,
  "white": #ffffff,
  "B300": #0071b8,
  "B400": #05639d,
  "B500": #05598d,
  "B600": #03436b,
  "K900": #24292d,
  "K800": #4e5256,
  "K700": #696f74,
  "K600": #83898e,
  "K500": #9da3a8,
  "K400": #b5bbc0,
  "K300": #ced3d6,
  "K200": #dde1e3,
  "K150": #edeef0,
  "K125": #f1f3f4,
  "K100": #f7f8f9,
  "status-green": #3eab60,
  "status-orange": #f19c2d,
  "status-red": #d75453,
  "status-blue": #42a8d5,
  "temperature": #f1593b,
  "feels-like": #f8954e,
  "pop": #53757d,
  "humidity": #55a4c1,
  "wind": #55c1ba,
  "zero-line": #ccc,
  "sunrise": #f19c2d,
  "moon": #435962,
  "moonlight": #f7f8f9,
  "moonrise": #87a9cd,
  "alert-background": #fdf6f6,
  "icon-cloud": #535b61,
  "icon-orange": #f19c2d,
  "icon-rain": #4cb9f0,
  "icon-snow": #849ad9,
  "icon-moon": #87a9cd,
);

:root {
  // Generate hex/rgb CSS vars for default/rgba usage, respectively
  @function hexToRGB($hex) {
    @return red($hex), green($hex), blue($hex);
  }
  @each $name, $color in $theme-colors {
    --#{$name}: #{$color};
    --#{$name}-rgb: #{hexToRGB($color)};
  }

  --font: "Red Hat Display", sans-serif;

  --input-height: 38px;

  --box-side-padding: 50px;
  --box-border-radius: 10px;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;

  background: var(--K150);

  color: var(--K800);
  line-height: 1;
}

body,
button,
input,
select {
  font-family: var(--font);
}

button {
  transition: 0.2s ease all;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0 14px;
  height: var(--input-height);

  border-radius: 4px;
  border: unset;
  background: var(--white);
  box-shadow: 1px 2px 8px rgba(var(--K800-rgb), 0.1);

  font-size: 16px;
  font-weight: 600;
  color: var(--K800);

  &:focus {
    outline: none;
  }

  &:hover {
    background: var(--K50);
    box-shadow: 2px 4px 12px rgba(var(--K800-rgb), 0.2);
  }

  &.button--icon {
    width: var(--input-height);
    padding: 0;

    svg {
      display: block;
      width: 24px;
      height: 24px;

      path {
        fill: var(--K800);
      }
    }
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.3;
}

@import "./layout.scss";
@import "./charts.scss";
